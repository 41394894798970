import type { IWixAPI } from '@wix/yoshi-flow-editor';

export function resolveLocale(wixCodeApi: IWixAPI): string | undefined {
  const { multilingual } = wixCodeApi.window;
  const locale =
    multilingual.isEnabled && multilingual.currentLanguage
      ? multilingual.siteLanguages.find((language) => language.languageCode === multilingual.currentLanguage)?.locale
      : wixCodeApi.site.regionalSettings ?? wixCodeApi.site.language;

  return locale || undefined;
}
