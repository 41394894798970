import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Pricing',
  props: {},
  methods: {
    setPricing: {
      params: [{ name: 'params' }],
    },
  },
  events: {},
});
